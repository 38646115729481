import React from 'react';
import { ContactsHeaderSection } from "../components/PageContacts/ContactsHeaderSection";
import { ContactsFormAndInfo } from "../components/PageContacts/ContactsFormAndInfo";

export function Contacts(props: any) {
  return (
    <>
      <ContactsHeaderSection ref={props.refs.getInTouch} />
      <ContactsFormAndInfo ref={props.refs.contacts} />
    </>
  )
}
