import React from 'react';

const ContactsHeaderSection = React.forwardRef<HTMLParagraphElement>((props, forwardedRef) => {

  // export function ContactsHeaderSection() {
  return (
    <div className="bg-gray-100">
      <div className="py-24 lg:py-32" ref={forwardedRef}>
        <div className="relative z-9 max-w-7xl mx-auto pl-4 pr-8 sm:px-6 lg:px-8">
          <h1 className="text-4xl font-extrabold tracking-tight text-gray-700 sm:text-5xl lg:text-6xl">
            Get in touch
          </h1>
          <p className="mt-6 text-xl text-gray-500 max-w-3xl">
            You may find contact information below or simply fill up the form and we will contact you shortly.
          </p>
        </div>
      </div>
    </div>

  )
})

export { ContactsHeaderSection };