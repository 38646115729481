import React from 'react';
import { Link } from "react-router-dom"
import { FooterSubscribe } from "./Footer/FooterSubscribe"
import RightByteLogo from '../image/Right-Byte_logo.svg';

/* This example requires Tailwind CSS v2.0+ */
const footerNavigation = {
  solutions: [
    { name: 'Cybersecurity', href: '#' },
  ],
  company: [
    { name: 'About', href: 'About' },
    { name: 'Contacts', href: 'Contacts' },
  ],
  social: [
    {
      name: 'LinkedIn',
      href: 'https://www.linkedin.com/company/right-byte-llc/',
      icon: (props: any) => (
        <svg className="w-5 h-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
          <path
            fillRule="evenodd"
            d="M16.338 16.338H13.67V12.16c0-.995-.017-2.277-1.387-2.277-1.39 0-1.601 1.086-1.601 2.207v4.248H8.014v-8.59h2.559v1.174h.037c.356-.675 1.227-1.387 2.526-1.387 2.703 0 3.203 1.778 3.203 4.092v4.711zM5.005 6.575a1.548 1.548 0 11-.003-3.096 1.548 1.548 0 01.003 3.096zm-1.337 9.763H6.34v-8.59H3.667v8.59zM17.668 1H2.328C1.595 1 1 1.581 1 2.298v15.403C1 18.418 1.595 19 2.328 19h15.34c.734 0 1.332-.582 1.332-1.299V2.298C19 1.581 18.402 1 17.668 1z"
            clipRule="evenodd"
          />
        </svg>
      ),
    },

  ],
}

export default function Footer() {
  return (
    <footer className="bg-warm-gray-900" aria-labelledby="footer-heading">
      <h2 id="footer-heading" className="sr-only">
        Footer
      </h2>
      <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:py-10 lg:px-8">

        <div className="grid grid-cols-1 gap-8 md:grid-cols-3 ">

          {/* Logo and social */}
          <div className="space-y-4 md:col-span-1">
            <div className="relative">
              <Link to="/">
                <span className="sr-only">Workflow</span>
                <img
                  className="h-12 w-auto"
                  src={RightByteLogo}
                  alt="Right-Byte logo"
                />
              </Link>
            </div>

            <p className="text-gray-400 text-base">
              Right researches by right researchers.
            </p>
            <div className="flex space-x-6">
              {footerNavigation.social.map((item) => (
                <a key={item.name} href={item.href} className=" text-gray-400 hover:text-gray-300">
                  <span className="sr-only">{item.name}</span>
                  <item.icon className="h-6 w-6" aria-hidden="true" />
                </a>
              ))}
            </div>
          </div>

          {/* Solutions and Company */}
          {/* <div className="grid grid-cols-2 gap-8 ">
            <div className="">
              <h3 className="text-sm font-semibold text-gray-200 tracking-wider uppercase">Solutions</h3>
              <ul className="mt-4 space-y-4">
                {footerNavigation.solutions.map((item) => (
                  <li key={item.name}>
                    <a href={item.href} className="text-base text-gray-400 hover:text-gray-300">
                      {item.name}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
            <div className="">
              <h3 className="text-sm font-semibold text-gray-200 tracking-wider uppercase">Company</h3>
              <ul className="mt-4 space-y-4">
                {footerNavigation.company.map((item) => (
                  <li key={item.name}>
                    <a href={item.href} className="text-base text-gray-400 hover:text-gray-300">
                      {item.name}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div> */}

          {/* Subscribe */}
          <div className="md:md:col-span-2">
            <FooterSubscribe className="space-y-6 md:flex md:flex-col lg:flex-row md:justify-between   " />
          </div>
        </div>



        <div className="mt-6 border-t border-gray-700 pt-8 ">
          <p className="text-base text-gray-400 text-center">
            &copy; {(new Date()).getFullYear()} Right-Byte LLC
          </p>
        </div>
      </div>

    </footer>
  )
}
