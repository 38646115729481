import React, { useRef } from 'react';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { HomeHeaderSection } from '../components/PageHome/HomeHeaderSection';
import { HomeActivitiesAndServices } from '../components/PageHome/HomeActivitiesAndServices'
import { HomeFeature } from '../components/PageHome/HomeFeature';
import { About } from './About';
import { Contacts } from './Contacts';

const activities = [
  {
    name: 'RESEARCHES',
    list: [
      'Cybersecurity',
      'Big Data',
      'Machine Learning',
      'Generative AI',
      'Privacy Enhancement'],
    emoji: '🔎'
  },
  {
    name: 'TECHNOLOGIES',
    list: [
      'RUST',
      'WASM',
      'GPGPU',
      'SWIFT',
      'TypeScript',
    ],
    emoji: '🚀'

  },
]

const services = [
  {
    name: 'CUSTOM DEVELOPMENT',
    list: [
      'Web Development',
      'Web Applications',
      'Browser Extensions',
      'Mobile Apps (iOS)',
      'ML models',
    ],
    emoji: '⚙️'

  },
  {
    name: 'PROJECT MANAGEMENT',
    list: [
      'App development management',
      'Support and maitenance',
      'Total Experience strategies',
      'Risk Assessment',
    ],
    emoji: '👨‍💼'

  },
]

export function Home() {

  const params = useParams()

  const blocks = {
    'about': useRef<HTMLParagraphElement>(null),
    'contacts': useRef<HTMLParagraphElement>(null),
    'getInTouch': useRef<HTMLParagraphElement>(null),
  };

  const scrollToBlock = (block = "") => {
    switch (block) {
      case 'about': blocks.about.current!.scrollIntoView({ behavior: "smooth" }); break;
      case 'contacts': blocks.contacts.current!.scrollIntoView({ behavior: "smooth" }); break;
      case 'getInTouch': blocks.getInTouch.current!.scrollIntoView({ behavior: "smooth" }); break;
      default: window.scrollTo({ top: 0, left: 0, behavior: "smooth" }); break;
    }
    // if (block in blocks) {
    //   blocks[block].current.scrollIntoView({
    //     behavior: "smooth"
    //   });
    // }
  };


  useEffect(() => {
    scrollToBlock(params.block);
  }, [params]);

  // useEffect(() => {
  //   window.scrollTo({
  //     top: 0,
  //     left: 0,
  //     behavior: "smooth"
  //   })
  // }, [])
  return (
    <>
      <HomeHeaderSection />
      {/* <HomeActivitiesAndServices /> */}

      <HomeFeature
        title='Activities'
        subtitle='We are focusing on the present-day challenges of the industry.'
        features={activities} />

      <HomeFeature
        title='Services'
        subtitle='Services we deliver with modern approach to professional development.'
        features={services} />

      <About ref={blocks.about} />
      <Contacts refs={blocks} />
      {/* <HomeContactUs/> */}
    </>
  );
}