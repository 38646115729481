
import React from 'react';
import { AboutCompanyCopy } from '../components/PageAbout/AboutCompanyCopy';
import { AboutCompany } from '../components/PageAbout/AboutCompany';
import { AboutTeam } from "../components/PageAbout/AboutTeam";

const About = React.forwardRef<HTMLParagraphElement>((props, forwardedRef) => {

// export function About() {
  return (
    <>
      {/* <section className="relative bg-white overflow-hidden"> */}
        <AboutCompany ref={forwardedRef}/>
        <AboutTeam />
      {/* </section> */}
    </>
  )
})
export {About}
