/* This example requires Tailwind CSS v2.0+ */
import React, { useState } from 'react';
import {Fragment} from 'react'
import {Popover, Transition} from '@headlessui/react'
import {MenuIcon, XIcon} from '@heroicons/react/outline'
import {Link} from 'react-router-dom'
import HeaderNavigation from './Header/HeaderNavigation'
import RightByteLogo from '../image/Right-Byte_logo.svg';

// const products = [
    // {
    //     name: 'Fuzzybird',
    //     description: 'The next-gen network examination security tool designed to identify and block external threats.',
    //     to: '/',
    //     srcImage: './image/GroupFBicon_grey.svg',
    // },
// ]


export default function TheHeader() {
  const [isShowing, setIsShowing] = useState(true)
  
    return (
        <Popover as="header" className="relative z-50  h-[100px] bg-gray-100 backdrop-blur-sm">
            <div className="max-w-7xl mx-auto px-4 sm:px-6">
                <div className="flex justify-between items-center  py-6  md:space-x-10">
                    <div className="flex justify-start lg:w-0 lg:flex-1">
                        <Link to="/">
                            <span className="sr-only">Workflow</span>
                            <img
                                className="h-12 w-auto"
                                src={RightByteLogo}
                                alt="Right-Byte logo"
                            />
                        </Link>
                        <div className="text-gray-500 text-sm pl-3 flex flex-col justify-center ">
                            <h2 className="font-bold pb-1 text-md sm:text-xl ">
                                Right-Byte
                            </h2>
                            <h3 className=" ">
                                Digital Lab
                            </h3>
                        </div>
                    </div>
                    <div className="-mr-2 -my-2 md:hidden">
                        <Popover.Button onClick={()=>setIsShowing(true)}
                            className="bg-transparent rounded-md p-2 inline-flex items-center justify-center text-gray-500 hover:text-gray-700 hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-teal-500">
                            <span className="sr-only">Open menu</span>
                            <MenuIcon className="h-6 w-6" aria-hidden="true"/>
                        </Popover.Button>
                    </div>

                    <Popover.Group className="hidden md:flex space-x-10">
                        <HeaderNavigation />
                    </Popover.Group>

                </div>
            </div>

            <Transition
                as={Fragment}
                // show={isShowing}
                enter="duration-200 ease-out"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="duration-100 ease-in"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
            >
                <Popover.Panel focus
                               className="absolute z-50 top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden">
                    <div
                        className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
                        <div className="pt-5 pb-6 px-5">
                            <div className="flex items-center justify-between">
                                <div>
                                    <img
                                        className="h-12 w-auto"
                                        src={RightByteLogo}
                                        alt="Right-Byte logo"
                                    />
                                </div>
                                <div className="text-gray-500 text-sm pl-3 flex flex-col justify-center -mt-3">
                                    <h2 className="font-bold text-center sm:text-xl ">
                                        Right-Byte
                                    </h2>
                                    <h3 className="text-center ">
                                        Digital Lab
                                    </h3>
                                </div>
                                <div className="-mr-2">
                                    <Popover.Button onClick={()=>setIsShowing(false)}
                                        className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-teal-500">
                                        <span className="sr-only">Close menu</span>
                                        <XIcon className="h-6 w-6" aria-hidden="true"/>
                                    </Popover.Button>
                                </div>
                            </div>
                            <div className="mt-6">
                                <nav className="grid gap-y-8">
                                    {/* {products.map((item) => (
                                        <li key={item.name} className="list-none">
                                            <Link
                                                to={item.to}
                                                className="-m-3 p-3 flex items-center rounded-md hover:bg-gray-50"
                                            >
                                                <img className="flex-shrink-0 h-6 w-6"
                                                     src={item.srcImage}
                                                     aria-hidden="true"
                                                     alt="Nest logo"/>
                                                <span
                                                    className="ml-3 text-base font-medium text-gray-900">{item.name}</span>
                                            </Link>
                                        </li>
                                    ))} */}
                                </nav>
                            </div>
                        </div>
                        <div className="py-6 px-5 space-y-6">
                            <div className="flex justify-around">
                                <Popover.Button as={Link} to="about" onClick={()=>setIsShowing(false)} className="text-base font-medium text-gray-500 hover:text-gray-800">
                                    About
                                </Popover.Button>

                                <Popover.Button as={Link} to="getInTouch" className="text-base font-medium text-gray-500 hover:text-gray-800">
                                    Contacts
                                </Popover.Button>
                            </div>

                        </div>
                    </div>
                </Popover.Panel>

            </Transition>

        </Popover>
    )
}
