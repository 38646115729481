import React from 'react'
import axios from 'axios'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { FormNotification } from './FormNotification'

function changeObjToString(object: { [index: string]: string; }) {
  let resultString = ``
  for (const key of Object.keys(object)) {
    if (object[key]) {
      resultString += `${key} - ${object[key]} \n`
    }
  }
  return resultString
}

const ContactsForm = React.forwardRef<HTMLParagraphElement>((props, forwardedRef) => {

  const classNameInput = (<div className="py-3 px-4 block w-full shadow-sm text-gray-900 focus:outline-none focus:ring-teal-500 focus:border-teal-500 focus:border-2 border border-gray-300 rounded-md" />).props.className;
  const classNameError = (<div className="text-red-500" />).props.className;

  const [isShowNotification, setIsShowNotification] = useState(false)
  const [isSuccessSend, setIsSuccessSend] = useState(true);

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({
    mode: "onBlur"
  });

  function showNotification() {
    setIsShowNotification(true);
    setTimeout(() => { setIsShowNotification(false) }, 3000)
  }

  function onSubmit(data: any) {
    const webHookURL = 'https://hooks.slack.com/services/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' // 
    // const webHookURL = 'https://hooks.slack.com/services/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' // for testing 

    const dataForSend = {
      text: changeObjToString(data)
    }

    axios
      .post(webHookURL, JSON.stringify(dataForSend))
      .then(response => {
        setIsSuccessSend(true);
        showNotification();
        reset();
      })
      .catch(error => {
        setIsSuccessSend(false);
        showNotification();
      });
  }

  return (
    <>
      <div className="py-10 px-6 sm:px-10 lg:col-span-2 xl:p-12" ref={forwardedRef}>
        <h3 className="text-lg font-medium text-gray-900">Send us a message</h3>
        <form onSubmit={handleSubmit(onSubmit)} action="#" method="POST" className="mt-6 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8">

          {/* Name */}
          <div>
            <label htmlFor="name" className="block text-sm font-medium text-gray-900">
              Name
            </label>
            <div className="mt-1">
              <input
                type="text"
                // name="name"
                id="name"
                autoComplete="given-name"
                {...register("name", {
                  required: {
                    value: true,
                    message: 'This field is required'
                  },
                })
                }
                className={classNameInput}
              />
              {errors.name && <span className={classNameError}>{errors.name?.message || "Error!"}</span>}
            </div>
          </div>

          {/* Email */}
          <div>
            <label htmlFor="email" className="block text-sm font-medium text-gray-900">
              Email
            </label>
            <div className="mt-1">
              <input
                id="email"
                // name="email"
                type="email"
                autoComplete="email"
                {...register("email", {
                  required: {
                    value: true,
                    message: 'This field is required'
                  },
                  pattern: {
                    value: /([A-z0-9_.-]{1,})@([A-z0-9_.-]{1,}).([A-z]{2,8})/,
                    message: 'This field is invalid'
                  }
                }
                )
                }
                className={classNameInput}
              />
              {errors.email && <span className={classNameError}>{errors.email?.message || "Error!"}</span>}
            </div>
          </div>

          {/* Phone */}
          <div>
            <div className="flex justify-between">
              <label htmlFor="phone" className="block text-sm font-medium text-gray-900">
                Phone
              </label>
              <span id="phone-optional" className="text-sm text-gray-500">
                Optional
              </span>
            </div>
            <div className="mt-1">
              <input
                type="tel"
                // name="phone"
                id="phone"
                autoComplete="tel"
                aria-describedby="phone-optional"
                {...register("phone", {
                  pattern: {
                    value: /([0-9_.-]{1,12})/,
                    message: 'This field is invalid'
                  }
                })
                }
                className={classNameInput}
              />
              {errors.phone && <span className={classNameError}>{errors.phone?.message || "Error!"}</span>}

            </div>
          </div>

          {/* Company */}
          <div>
            <div className="flex justify-between">
              <label htmlFor="company" className="block text-sm font-medium text-gray-900">
                Company
              </label>
              <span id="company-optional" className="text-sm text-gray-500">
                Optional
              </span>
            </div>
            <div className="mt-1">
              <input
                type="text"
                // name="company"
                id="company"
                aria-describedby="company-optional"
                {...register("company", { required: false })}
                className={classNameInput}
              />
            </div>
          </div>

          {/* subject */}
          <div className="sm:col-span-2">
            <div className="flex justify-between">
              <label htmlFor="subject" className="block text-sm font-medium text-gray-900">
                Subject
              </label>
              <span id="subject-optional" className="text-sm text-gray-500">
                Optional
              </span>
            </div>

            <div className="mt-1">
              <input
                type="text"
                // name="subject"
                id="subject"
                aria-describedby="subject-optional"
                {...register("subject", { required: false })}
                className={classNameInput}
              />
            </div>
          </div>

          {/* Message */}
          <div className="sm:col-span-2">
            <label htmlFor="message" className="block text-sm font-medium text-gray-900">
              Message
            </label>
            <div className="mt-1">
              <textarea
                id="message"
                // name="message"
                rows={4}
                {...register("message", {
                  required: {
                    value: true,
                    message: 'This field is required'
                  }
                })
                }
                className="py-3 px-4 block w-full shadow-sm text-gray-900 focus:outline-none focus:ring-teal-500 focus:border-teal-500 focus:border-2 border border-gray-300 rounded-md"
                aria-describedby="message-max"
                defaultValue={''}
              />
              {errors.message && <span className={classNameError}>{errors.message?.message || "Error!"}</span>}

            </div>
          </div>

          {/* Send */}
          <div className="sm:col-span-2 sm:flex sm:justify-end">
            <button
              type="submit"
              className="mt-2 w-full inline-flex items-center justify-center px-6 py-3 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-teal-600 hover:bg-teal-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500 sm:w-auto "
            >
              Send
            </button>
          </div>
        </form>
      </div>

      <FormNotification show={isShowNotification} setShow={setIsShowNotification} isSuccessSend={isSuccessSend} />
    </>

  )
})

export { ContactsForm }
