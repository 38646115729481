import React from 'react';
import { CheckIcon } from '@heroicons/react/outline'

const activities = [
  {
    name: 'RESEARCHES',
    list: [
      'Cybersecurity',
      'Big Data',
      'Machine Learning',
      'Generative AI',
      'Privacy Enhancement'],
    emoji: '🔎'
  },
  {
    name: 'TECHNOLOGIES',
    list: [
      'RUST',
      'WASM',
      'GPGPU',
      'SWIFT',
      'TypeScript',
    ],
    emoji: '🚀'

  },
]

const services = [
  {
    name: 'CUSTOM DEVELOPMENT',
    list: [
      'Web Development',
      'Web Applications',
      'Browser Extensions',
      'Mobile Apps (iOS)',
      'ML models',
    ],
    emoji: '⚙️'

  },
  {
    name: 'PROJECT MANAGEMENT',
    list: [
      'Apps development management',
      'Support and maitenance',
      'Total Experience strategies',
      'Risk Assessment',
    ],
    emoji: '👨‍💼'

  },
]

const features = [
  { name: 'Invite team members', description: 'You can manage phone, email and chat conversations all from a single mailbox.', },
  { name: 'List view', description: 'You can manage phone, email and chat conversations all from a single mailbox.' },
  { name: 'Keyboard shortcuts', description: 'You can manage phone, email and chat conversations all from a single mailbox.' },
  { name: 'Calendars', description: 'You can manage phone, email and chat conversations all from a single mailbox.' },
  { name: 'Notifications', description: 'Find what you need with advanced filters, bulk actions, and quick views.' },
  { name: 'Boards', description: 'Find what you need with advanced filters, bulk actions, and quick views.' },
  { name: 'Reporting', description: 'Find what you need with advanced filters, bulk actions, and quick views.' },
  { name: 'Mobile app', description: 'Find what you need with advanced filters, bulk actions, and quick views.' },
]
type typeHomeFeature = {
  title: string,
  subtitle: string,
  features: {
    name: string,
    emoji: string,
    list: string[]
  }[],
}

export function HomeFeature({ title, subtitle, features }: typeHomeFeature) {
  return (
    <div className="bg-white">
      <div className="max-w-7xl mx-auto pt-16 px-4 sm:px-6 lg:pt-24 lg:px-8 lg:grid lg:grid-cols-3 lg:gap-x-8">
        <div>
          {/* <h2 className="text-base font-semibold text-indigo-600 uppercase tracking-wide">Everything you need</h2> */}
          <p className="text-3xl font-extrabold text-gray-700">{title}</p>
          <p className="mt-4 text-lg text-gray-500">{subtitle} </p>
        </div>

        <div className="mt-12 lg:mt-0 lg:col-span-2">
          <div className="sm:grid sm:grid-cols-2 gap-x-5">
            {
              features.map(feature => (
                <div key={feature.name} className="mb-14 pl-2 sm:pl-0 sm:mb-0">
                  {/* <h4 className="text-xl font-bold text-gray-900 mb-3 sm:mb-6">{feature.name}</h4> */}
                  {/* <h4 className="text-xl font-bold text-gray-900 mb-3 sm:mb-6" >{`${feature.emoji} ${feature.name}`}</h4> */}
                  <h4 className="text-xl font-bold text-gray-900 mb-4 sm:mb-8" >
                    <span className="p-2 rounded-md mr-2 bg-teal-500">{feature.emoji}</span>
                    <span>{feature.name}</span>
                  </h4>

                  <ul className='space-y-2 sm:space-y-4'>
                    {
                      feature.list.map(item => (
                        <li key={item}>
                          <CheckIcon className="absolute h-6 w-6 text-green-500" aria-hidden="true" />
                          <p className="ml-9 text-lg leading-6 font-medium text-gray-900">{item}</p>
                        </li>
                      ))
                    }
                  </ul>
                </div>
              ))
            }
          </div>

          {/*
          <ul className="space-y-10 sm:space-y-0 sm:grid sm:grid-cols-2 sm:grid-rows-4 sm:grid-flow-col sm:gap-x-6 sm:gap-y-10 lg:gap-x-8">
             {features.map((feature) => (
              <div key={feature.name} className="relative">
                <li>
                  <CheckIcon className="absolute h-6 w-6 text-green-500" aria-hidden="true" />
                  <p className="ml-9 text-lg leading-6 font-medium text-gray-900">{feature.name}</p>
                </li>
              </div>
            ))} 

          </ul>
          */}
        </div>
      </div>
    </div>
  )
}
