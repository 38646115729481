import React from 'react';
import { Link } from 'react-router-dom';
import homePage from '../../image/home_page.png';

export  function HomeHeaderSection() {
  return (
    <div className="bg-gray-100 lg:pb-6">
      <div className="relative  max-w-7xl mx-auto shadow-lg">
        <div className="max-w-7xl  h-full ">
          <div className="relative z-10 pb-8 bg-white sm:pb-16 md:pb-20 lg:w-5/12 lg:py-28 xl:pb-32 ">
            <svg
              className="hidden lg:block absolute right-0 inset-y-0 h-full w-24 text-white transform translate-x-full"
              fill="currentColor"
              viewBox="0 0 100 100"
              preserveAspectRatio="none"
              aria-hidden="true"
            >
              {/* <polygon points="50,0 100,0 50,100 " /> */}
              <polygon points="0,0 100,0 0,100 " />
            </svg>


            
            <div className="mt-10 mx-auto px-4 h-full sm:mt-12 sm:px-6 md:mt-16 lg:mt-0 sm:text-center lg:text-left space-y-6">
              <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl space-y-6">
                <span className="block xl:inline">Right-Byte</span>{' '}
                <span className="block text-teal-600 text-3xl  sm:text-4xl">Digital Lab Company</span>
              </h1>
              <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
                Right researches by right researchers.
              </p>
              <div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
                  <div className="rounded-md shadow">
                    <Link
                      to="/contacts"
                      className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-teal-600 hover:bg-teal-700 md:py-4 md:text-lg md:px-10"
                    >
                      Set up a FREE consultation
                    </Link>
                  </div>
                
                </div>
            </div> 
           

            {/* <div className="mt-10 mx-auto max-w-7xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28">
              <div className="sm:text-center lg:text-left">
                <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
                  <span className="block xl:inline">Right-Byte</span>{' '}
                  <span className="block text-indigo-600 xl:inline">Digital Lab Company</span>
                </h1>
                <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
                  Right researches by right researchers.
                </p>
                <div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
                  <div className="rounded-md shadow">
                    <a
                      href="/"
                      className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 md:py-4 md:text-lg md:px-10"
                    >
                      Get started
                    </a>
                  </div>
                
                </div>
              </div>
            </div> */}

          </div>
        </div>

        <div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-8/12">
          <img
            className="h-56 w-full object-cover sm:h-72 md:h-96 lg:w-full lg:h-full"
            src={homePage}
            alt=""
          />
        </div>
      </div>
    </div>
  )
}
