
import React from 'react';
import { Home } from './pages/Home'
import Nest from './pages/Nest'
import { Contacts } from './pages/Contacts';
import { About } from './pages/About';
import { Routes, Route } from 'react-router-dom'
import Layout from './components/Layout';
// import Example from './components/Example';


export default function App() {

  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Home />} />
        <Route path=":block" element={<Home />} />

        {/* <Route path="Nest" element={<Nest />} />
        <Route path="About" element={<About />} />
        <Route path="Contacts" element={<Contacts />} /> */}
        {/* <Route path="*" element={<NotFoundPage />} /> */}
      </Route>
    </Routes>
  );
}

