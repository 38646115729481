import React, { useState } from 'react';
import axios from 'axios'
import { useForm } from 'react-hook-form'
import { FormNotification } from '../PageContacts/FormNotification'

export function FooterSubscribe({ className }: { className: string }) {

  const [isShowNotification, setIsShowNotification] = useState(false)
  const [isSuccessSend, setIsSuccessSend] = useState(true);

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({
    mode: "onSubmit"
  });

  function showNotification() {
    setIsShowNotification(true);
    setTimeout(() => { setIsShowNotification(false) }, 3000)
  }

  function onSubmit(data: any) {
    const webHookURL = 'https://hooks.slack.com/services/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' // for testing 

    axios
      .post(webHookURL, JSON.stringify({text: data.email}))
      .then(response => {
        setIsSuccessSend(true);
        showNotification();
        reset();
      })
      .catch(error => {
        setIsSuccessSend(false);
        showNotification();
      });
  }

  return (
    <div className={` ${className}`}>
      <div className="space-y-4">
        <h3 className="text-sm font-semibold text-gray-200 tracking-wider uppercase">
          Get Updates
        </h3>
        <p className="mt-2 text-base text-gray-400">
          Get the latest app version, news & updates. <br className="hidden md:block lg:hidden" /> Subscribe to our newsletter.
        </p>
      </div>
      <form onSubmit={handleSubmit(onSubmit)} action="#" method="POST" className="mt-4 sm:flex sm:max-w-md lg:items-start lg:mt-0 lg:w-full">

        {/* Email */}
        <fieldset className="w-full">
          <label htmlFor="email_subscribe" className="block text-sm font-medium text-gray-900 sr-only">
            Email
          </label>
          <div className="">
            <input
              id="email_subscribe"
              // name="email"
              type="email"
              autoComplete="email"
              placeholder="Enter your email address"
              {...register("email", {
                required: {
                  value: true,
                  message: 'This field is required'
                },
                pattern: {
                  value: /([A-z0-9_.-]{1,})@([A-z0-9_.-]{1,}).([A-z]{2,8})/,
                  message: 'This field is invalid'
                }
              }
              )
              }
              className="py-3 px-4 block w-full shadow-sm text-gray-900 focus:outline-none focus:ring-teal-500 focus:border-teal-500 focus:border-2 border border-gray-300 rounded-md"
            />
            {errors.email && <span className='text-red-600'>{errors.email?.message || "Error!"}</span>}
          </div>
        </fieldset>

        {/* Subscribe */}
        <div className="sm:col-span-2 sm:flex sm:justify-end">
          <button
            type="submit"
            className="mt-2 sm:mt-0 sm:ml-2 w-full inline-flex items-center justify-center px-6 py-3 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-teal-600 hover:bg-teal-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500 focus:ring-offset-warm-gray-800 sm:w-auto "
          >
            Subscribe
          </button>
        </div>
      </form>
      <FormNotification show={isShowNotification} setShow={setIsShowNotification} isSuccessSend={isSuccessSend} />
    </div>

  )
}
