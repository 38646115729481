/*
  This example requires Tailwind CSS v2.0+ 
  
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/aspect-ratio'),
    ],
  }
  ```
*/
import React from 'react';
import SerhiiStelmakhFoto from '../../image/Team/SerhiiStelmakh.jpg';

const people = [
  {
    name: 'Serhii Stelmakh, PhD',
    role: 'Team Lead',
    imageUrl: SerhiiStelmakhFoto,
    bio: 'Research division supervision',
    linkedinUrl: 'https://www.linkedin.com/in/serhii-stelmakh-phd-47693514b/#',
  },
]

export function AboutTeam() {
  return (
    <div className="bg-white">
      <div className="mx-auto py-12 px-4 max-w-7xl sm:px-6 lg:px-8 lg:py-24">
        <div className="space-y-12 lg:grid lg:grid-cols-3 lg:gap-8 lg:space-y-0">
          <div className="space-y-5 sm:space-y-4">
            {/* <h2 className="text-3xl font-extrabold tracking-tight sm:text-4xl">Meet our leadership</h2> */}
            <h2 className="text-4xl font-extrabold tracking-tight text-gray-700 sm:text-5xl lg:text-6xl">Meet our leadership</h2>
            <p className="text-xl text-gray-500">
              Find out who's on the helm!
            </p>
          </div>
          <div className="lg:col-span-2">
            <ul className="space-y-12 sm:divide-y sm:divide-gray-200 sm:space-y-0 sm:-mt-8 lg:gap-x-8 lg:space-y-0">
              {
                people.map((person) => (
                  <li key={person.name} className="sm:py-8">
                    <div className="space-y-4 sm:grid sm:grid-cols-3 sm:items-start sm:gap-6 sm:space-y-0">
                      <div className="aspect-w-3 aspect-h-3 sm:aspect-w-3 sm:aspect-h-4 ">
                        <img className="object-cover object-top shadow-lg rounded-lg " src={person.imageUrl} alt={person.name} />
                        {/* <img className="object-cover shadow-lg rounded-lg" src={person.imageUrl} alt="" /> */}
                      </div>
                      <div className="sm:col-span-2">
                        <div className="space-y-4">
                          <div className="text-lg leading-6 font-medium space-y-1">
                            <h3>{person.name}</h3>
                            <p className="text-teal-600">{person.role}</p>
                          </div>
                          <div className="text-lg">
                            <p className="text-gray-500">{person.bio}</p>
                          </div>

                          <a href={person.linkedinUrl} target="_blank" className=" text-gray-400 hover:text-gray-500" rel="noreferrer">
                            <span className="sr-only">LinkedIn</span>
                            <svg className="w-5 h-5 mt-4" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
                              <path
                                fillRule="evenodd"
                                d="M16.338 16.338H13.67V12.16c0-.995-.017-2.277-1.387-2.277-1.39 0-1.601 1.086-1.601 2.207v4.248H8.014v-8.59h2.559v1.174h.037c.356-.675 1.227-1.387 2.526-1.387 2.703 0 3.203 1.778 3.203 4.092v4.711zM5.005 6.575a1.548 1.548 0 11-.003-3.096 1.548 1.548 0 01.003 3.096zm-1.337 9.763H6.34v-8.59H3.667v8.59zM17.668 1H2.328C1.595 1 1 1.581 1 2.298v15.403C1 18.418 1.595 19 2.328 19h15.34c.734 0 1.332-.582 1.332-1.299V2.298C19 1.581 18.402 1 17.668 1z"
                                clipRule="evenodd"
                              />
                            </svg>
                          </a>
                        </div>
                      </div>
                    </div>
                  </li>
                ))
              }
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}
