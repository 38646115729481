import React from 'react';
import Header from './Header';
import Footer from './Footer';

import { Outlet } from 'react-router-dom'
// import Example from './components/Example';


export default function App() {

  return (
    <>
      <Header />
      <main className="relative z-10 overflow-hidden flex-grow ">
        <Outlet />
      </main>
      <Footer />
      
    </>
  );
}