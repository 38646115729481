/* This example requires Tailwind CSS v2.0+ */
import React from 'react';
import { Fragment } from 'react'
import { Popover, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/solid'
import { Link } from 'react-router-dom'

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

type typeProduct = {
  name: string,
  description: string,
  to: string,
  srcImage: string,
}

export default function HeaderNavigation() {
  return (
    <>
      {/*<Popover  className="relative">*/}
      {/*  {({ open }) => (*/}
      {/*    <>*/}
      {/*      <Popover.Button*/}
      {/*        className={classNames(*/}
      {/*          open ? 'text-gray-900' : 'text-gray-500',*/}
      {/*          'group bg-transparent rounded-md inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500'*/}
      {/*        )}*/}
      {/*      >*/}
      {/*        <span>Products</span>*/}
      {/*        <ChevronDownIcon*/}
      {/*          className={classNames(*/}
      {/*            open ? 'text-gray-600' : 'text-gray-400',*/}
      {/*            'ml-2 h-5 w-5 group-hover:text-gray-500'*/}
      {/*          )}*/}
      {/*          aria-hidden="true"*/}
      {/*        />*/}
      {/*      </Popover.Button>*/}

      {/*      <Transition*/}
      {/*        as={Fragment}*/}
      {/*        enter="transition ease-out duration-200"*/}
      {/*        enterFrom="opacity-0 translate-y-1"*/}
      {/*        enterTo="opacity-100 translate-y-0"*/}
      {/*        leave="transition ease-in duration-150"*/}
      {/*        leaveFrom="opacity-100 translate-y-0"*/}
      {/*        leaveTo="opacity-0 translate-y-1"*/}
      {/*      >*/}
      {/*        <Popover.Panel  className="absolute z-50 -ml-4 mt-3 transform px-2 w-screen max-w-md sm:px-0 lg:ml-0 left-1/2 -translate-x-1/2">*/}
      {/*          <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">*/}
      {/*            <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">*/}
      {/*              {products.map((product) => (*/}
      {/*                <Link*/}
      {/*                  key={product.name}*/}
      {/*                  to={product.to}*/}
      {/*                  className="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50"*/}
      {/*                >*/}
      {/*                  <img className="flex-shrink-0 h-6 w-6"*/}
      {/*                    src={product.srcImage}*/}
      {/*                    aria-hidden="true"*/}
      {/*                    alt={`${product.name} logo`} />*/}
      {/*                  <div className="ml-4">*/}
      {/*                    <p className="text-base font-medium text-gray-900">{product.name}</p>*/}
      {/*                    <p className="mt-1 text-sm text-gray-500">{product.description}</p>*/}
      {/*                  </div>*/}
      {/*                </Link>*/}
      {/*              ))}*/}
      {/*            </div>*/}

      {/*          </div>*/}
      {/*        </Popover.Panel>*/}
      {/*      </Transition>*/}
      {/*    </>*/}
      {/*  )}*/}
      {/*</Popover>*/}

      <Link to="about" className="text-base font-medium text-gray-500 hover:text-gray-800">
        About
      </Link>
      <Link to="getInTouch" className="text-base font-medium text-gray-500 hover:text-gray-800">
        Contacts
      </Link>
    </>
  )
}
